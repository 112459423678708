import { logger, PROCESS_NAMES } from "@/utils/logger";
export const TOKEN_NAMES = {
  WORKY_ADMIN_TOKEN: "watoken",
  WORKY_ADMIN_REFRESH: "warefresh",
  SESSION_TOKEN: "token",
  SESSION_REFRESH: "refresh"
};
export const STORAGE_KEYS = {
  LAST_REFRESH: "worky_last_token_refresh",
  REFRESH_IN_PROGRESS: "worky_refresh_in_progress",
  LAST_REFRESH_WORKY_ADMIN: "worky_admin_last_token_refresh",
  REFRESH_IN_PROGRESS_WORKY_ADMIN: "worky_admin_refresh_in_progress",
  MASTER_TAB_ID: "worky_master_tab_id"
};
export const TIME_CONSTANTS = {
  REFRESH_INTERVAL: 4 * 60 * 1e3
  // 4 minutes
};
export const REFRESH_STATES = {
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  ERROR: "error"
};
export const createUniqueTabId = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};
export const getMasterTabId = () => {
  return localStorage.getItem(STORAGE_KEYS.MASTER_TAB_ID) || null;
};
export const setMasterTabId = (tabId) => {
  if (localStorage.getItem(STORAGE_KEYS.MASTER_TAB_ID)) {
    logger.warn(PROCESS_NAMES.TOKEN_REFRESH, "Master tab id already set");
    return;
  }
  localStorage.setItem(STORAGE_KEYS.MASTER_TAB_ID, tabId);
};
export const removeMasterTabId = () => {
  localStorage.removeItem(STORAGE_KEYS.MASTER_TAB_ID);
};
export const setWATokens = (token, refresh) => {
  localStorage.setItem(TOKEN_NAMES.WORKY_ADMIN_TOKEN, token);
  localStorage.setItem(TOKEN_NAMES.WORKY_ADMIN_REFRESH, refresh);
};
export const setSessionTokens = (token, refresh) => {
  if (token) {
    localStorage.setItem(TOKEN_NAMES.SESSION_TOKEN, token);
  }
  if (refresh) {
    localStorage.setItem(TOKEN_NAMES.SESSION_REFRESH, refresh);
  }
};
export const getWATokens = () => {
  return {
    token: localStorage.getItem(TOKEN_NAMES.WORKY_ADMIN_TOKEN),
    refresh: localStorage.getItem(TOKEN_NAMES.WORKY_ADMIN_REFRESH)
  };
};
export const getSessionTokens = () => {
  return {
    token: localStorage.getItem(TOKEN_NAMES.SESSION_TOKEN),
    refresh: localStorage.getItem(TOKEN_NAMES.SESSION_REFRESH)
  };
};
export const setLastRefreshTime = () => {
  localStorage.setItem(STORAGE_KEYS.LAST_REFRESH, Date.now().toString());
};
export const getLastRefreshTime = () => {
  return parseInt(localStorage.getItem(STORAGE_KEYS.LAST_REFRESH) || "0");
};
export const setRefreshInProgress = (inProgress) => {
  if (inProgress) {
    localStorage.setItem(STORAGE_KEYS.REFRESH_IN_PROGRESS, "true");
  } else {
    localStorage.removeItem(STORAGE_KEYS.REFRESH_IN_PROGRESS);
  }
};
export const isRefreshInProgress = () => {
  return !!localStorage.getItem(STORAGE_KEYS.REFRESH_IN_PROGRESS);
};
