const LOG_LEVELS = {
  DEBUG: "debug",
  INFO: "info",
  WARN: "warn",
  ERROR: "error"
};
const PROCESS_NAMES = {
  TOKEN_REFRESH: "Token Refresh",
  SET_SESSION_TOKENS: "Set Session Tokens",
  SESSION_VALIDATION: "Session Validation",
  AUTH: "Authentication"
};
class Logger {
  constructor() {
    this.isProduction = process.env.NODE_ENV === "production";
    this.debugEnabled = !this.isProduction;
  }
  _formatMessage(process2, message) {
    return `[${process2}] ${message}`;
  }
  _shouldLog(level) {
    if (level === LOG_LEVELS.ERROR)
      return true;
    return this.debugEnabled;
  }
  debug(process2, message, ...args) {
    if (!this._shouldLog(LOG_LEVELS.DEBUG))
      return;
    console.debug(this._formatMessage(process2, message), ...args);
  }
  info(process2, message, ...args) {
    if (!this._shouldLog(LOG_LEVELS.INFO))
      return;
    console.info(this._formatMessage(process2, message), ...args);
  }
  warn(process2, message, ...args) {
    if (!this._shouldLog(LOG_LEVELS.WARN))
      return;
    console.warn(this._formatMessage(process2, message), ...args);
  }
  error(process2, message, error) {
    console.error(this._formatMessage(process2, message), error);
  }
}
export const logger = new Logger();
export { PROCESS_NAMES };
